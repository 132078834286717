<template>
  <div
    class="text-xl w-full font-semibold text-center text-t3 my-64"
    v-if="loadinger"
  >
    <PulseLoader
      :loading="loadinger"
      color="#00ADEE"
      size="16px"
      class="mb-6"
    ></PulseLoader>
    <p>Loading. Please Hold.</p>
  </div>
  <Transition name="slide-fade" appear>
    <div v-if="!loadinger">
      <!-- Start top card -->
      <div
        class="bg-white rounded shadow-t3 items-center px-5 py-2 relative grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5 mt-6 md:mt-0"
      >
        <div>
          <div
            tabindex="0"
            aria-label="card 1"
            class="focus:outline-none bg-white p-6 space-y-1 dark:bg-t3-nightCard dark:text-white"
          >
            <div
              class="flex items-center justify-between pb-5 dark:border-t3-light"
            >
              <img
                :src="assessment.customer_image"
                alt="profile photo"
                class="w-20 h-20 rounded-xl"
              />
              <div
                class="flex items-start justify-between w-full overflow-hidden"
              >
                <div class="pl-3 w-5/6">
                  <p
                    tabindex="0"
                    class="focus:outline-none text-xl text-t3 font-medium leading-5"
                  >
                    {{ assessment.certificate_name }}
                  </p>
                  <p
                    tabindex="0"
                    class="focus:outline-none text-sm leading-normal pt-2 text-gray-500 dark:text-t3-nightText"
                  >
                    {{ assessment.customer_id }}
                  </p>
                  <p
                    tabindex="0"
                    class="focus:outline-none text-sm leading-normal pt-2 text-gray-500 dark:text-t3-nightText"
                  >
                    {{ assessment.customer_email }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-6 mx-auto">
          <p class="text-md text-gray-400 tracking-wide">
            Time:
            <span class="text-t3 font-medium">{{ assessment.start_time }}</span>
          </p>
          <p class="text-md text-gray-400 tracking-tight">
            Date:
            <span class="text-t3 font-medium">{{
              new Date(assessment.date).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            }}</span>
          </p>
          <p
            v-if="assessment.status !== 'sealed'"
            class="text-md text-gray-400 tracking-tight"
          >
            Status:
            <span
              class="font-medium capitalize"
              :class="statusColor(assessment.status)"
              >{{ assessment.status }}</span
            >
          </p>
          <p
            v-if="assessment.status === 'sealed'"
            class="text-md text-gray-400 tracking-tight"
          >
            Status:
            <span
              class="font-medium capitalize"
              :class="statusColor(assessment.status)"
              >Completed (Sealed)</span
            >
          </p>
          <p
            v-if="assessment.status === 'cancelled'"
            class="text-md text-gray-400 tracking-tight"
          >
            Reason:<span class="font-medium capitalize text-t3-cancel">{{
              assessment.cancel_reason
            }}</span>
          </p>
          <!-- <p v-if="assessment.status === 'sealed'" class="text-md text-gray-400 tracking-tight">Sealed:{{assessment.status.sealed}}</p> -->
        </div>

        <div
          class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id="exampleModalCenter"
          tabindex="-1"
          aria-labelledby="exampleModalCenterTitle"
          aria-modal="true"
          role="dialog"
        >
          <div
            class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
          >
            <div
              class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
            >
              <div
                class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md"
              >
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalScrollableLabel"
                >
                  Assessment Cancellation
                </h5>
                <button
                  type="button"
                  class="btn-close box-content w-4 h-4 p-1 text-t3 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body relative p-4">
                <p>Clarify the cancellation reason</p>
                <input
                  v-model="cancelReason"
                  type="text"
                  placeholder="Type the reason of cancellation"
                  class="form-control mt-6 block w-full p-3 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                />
              </div>
              <div
                class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md"
              >
                <button
                  @click="cancelled"
                  type="button"
                  class="inline-block px-6 py-2.5 bg-t3-cancel text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-cancel hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                >
                  Confirm Cancellation
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id="raiseDisputeModal"
          tabindex="-1"
          aria-labelledby="raiseDisputeModalTitle"
          aria-modal="true"
          role="dialog"
        >
          <div
            class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
          >
            <div
              class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
            >
              <div
                class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md"
              >
                <h5
                  class="text-xl font-medium leading-normal text-gray-800"
                  id="exampleModalScrollableLabel"
                >
                  Raise Dispute
                </h5>
                <button
                  id="disputeClose"
                  type="button"
                  class="btn-close box-content w-4 h-4 p-1 text-t3 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body relative p-4">
                <p>Clarify Dispute Reason</p>
                <textarea
                  v-model="disputeReason"
                  rows="6"
                  placeholder="Type the reason of dispute in four or less lines."
                  class="form-control mt-6 block w-full p-3 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                ></textarea>
                <p class="mt-6">Customer Medium</p>
                <input
                  v-model="staffReason"
                  type="text"
                  placeholder="Type the customer medium"
                  class="form-control mt-6 block w-full p-3 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                />
              </div>
              <div
                class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md"
              >
                <button
                  @click="reqSend"
                  type="button"
                  class="inline-block px-6 py-2.5 bg-t3 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-light hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                >
                  Raise Dispute
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class=" mx-auto">
			<p>Final Level</p>
			<h3 class="text-xl text-center font-bold sm:text-2xl">{{assessment.elpLevel}}</h3>
			<p>Certificate Expiry Date: <span>{{assessment.assessmentDate}}</span> </p>
		</div> -->
        <!-- <div class="block w-full"> -->
        <div
          class="mx-auto 2xl:ml-auto 2xl:mr-4 text-center 2xl:col-span-1 col-span-2"
        >
          <div class="flex w-full">
            <div
              v-if="
                assessment.status === 'completed' ||
                assessment.status === 'sealed'
              "
              class="2xl:ml-auto 2xl:mr-4 items-center text-center flex"
            >
              <router-link
                v-if="
                  scopeCheck('print_certificates') && assessment.final_level > 3
                "
                :to="{
                  name: 'AssessmentCertificate',
                  params: { id: assessment.id },
                }"
              >
                <div class="block text-center items-center p-3">
                  <img
                    src="../assets/certificate.svg"
                    class="h-10 w-10 m-auto object-cover"
                  />
                  <p class="text-t3 text-center">Certificate</p>
                </div>
              </router-link>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#raiseDisputeModal"
                v-if="checkDisputeTime() && scopeCheck('raise_dispute')"
                class="ml-12 hover:text-t3-secondary text-t3"
              >
                <div class="block text-center items-center p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-10 h-10 m-auto"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>

                  <p class="text-center">Raise Dispute</p>
                </div>
              </button>
              <div class="ml-24">
                <p class="text-gray-600 text-left mr-2">
                  Final Level: {{ assessment.final_level }}
                </p>
                <p class="text-gray-600 text-left mr-2">
                  Expiration: {{ expirydate() }}
                </p>
              </div>
            </div>
            <div
              v-if="
                assessment.status !== 'completed' &&
                assessment.status !== 'sealed'
              "
              class="2xl:ml-auto 2xl:mr-4 items-center text-center flex"
            >
              <a
                href="https://objectstorage.me-dubai-1.oraclecloud.com/n/accelaero/b/dxb_external_t3a_elp_prod/o/static%2FICAO-Descriptors.pdf"
                target="_blank"
                class="ml-auto mr-8"
                ><button
                  class="font-medium bg-t3 p-3 text-white rounded-md hover:text-white hover:bg-t3-secondary"
                >
                  Show ICAO Descriptors
                </button></a
              >
            </div>
            <div
              v-if="
                assessment.status === 'booked' ||
                assessment.status === 'ongoing'
              "
              class="ml-auto flex gap-3 flex-col mr-4 text-center"
            >
              <!-- <button @click="showSample=true"  class="bg-t3 hover:text-t3 hover:bg-t3-secondary font-medium p-3 text-white rounded-md" >Show Sample</button> -->
              <button
                @click="onGo"
                class="font-medium text-t3 rounded-md"
                v-if="
                  !isCompany &&
                  assessmentCustomer(assessment.customer).customerprofile !==
                    null
                "
              >
                <img
                  src="../assets/start.svg"
                  class="h-7 w-7 m-auto flex object-cover"
                />
                <span v-if="assessment.status === 'ongoing'">Re-</span>Start
                Assessment
              </button>
              <button
                v-if="scopeCheck('cancel_assessment')"
                class="font-medium text-center text-t3-cancel"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCenter"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End top card -->
      <!-- Start Reports and Additional Info Card -->
      <div class="mt-5 grid md:grid-cols-3 grid-cols-1 gap-4">
        <div class="md:col-span-2">
          <!-- Start OPE Card -->
          <Transition name="slide-fade" appear>
            <div
              v-if="
                (betweenSet('ope') &&
                  (assessorType('ele') || assessorType('ope'))) ||
                scopeCheck('view_all_reports') ||
                checkSet()
              "
            >
              <div
                class="bg-white rounded-t shadow-t3 flex justify-between p-5"
                type="button"
              >
                <div class="ml-4">
                  <div v-if="currentOPE" class="flex items-center text-md">
                    <div class="relative w-10 h-10 mr-3 rounded-xl">
                      <img
                        class="object-cover w-full h-full rounded-xl"
                        :src="currentOPE.profile_img"
                        loading="lazy"
                      />
                      <div
                        class="absolute inset-0 rounded-xl"
                        aria-hidden="true"
                      ></div>
                    </div>
                    <div v-if="currentOPE">
                      <p class="font-medium text-t3">
                        {{ currentOPE.full_name }}
                      </p>
                      <p class="text-xs text-gray-600">OPE</p>
                    </div>
                  </div>
                </div>
                <button
                  @click="addingOPE = false"
                  v-if="
                    reportO.tasklevel_set &&
                    addingOPE &&
                    assessorType('ope') &&
                    assessment.status !== 'sealed'
                  "
                  class="font-medium text-center ml-auto mr-6 text-blue-600"
                >
                  Update Report
                </button>
                <button
                  @click="cancelAddOPE"
                  v-if="!addingOPE && assessorType('ope')"
                  class="font-medium text-center ml-auto mr-6 text-t3-cancel"
                >
                  Cancel
                </button>
                <div
                  v-if="reportO.level"
                  class="mr-6 justify-between flex items-center"
                >
                  <div class="text-gray-600">
                    Report Level: {{ reportO.level }}
                  </div>
                </div>
              </div>
              <div
                id="opeTable"
                class="bg-white shadow-t3 rounded-b px-5 pb-4 pt-2 overflow-x-scroll"
              >
                <!-- Adding Table -->
                <div>
                  <div
                    class="text-xl w-full font-semibold text-center text-t3 my-24"
                    v-if="!reportO.tasklevel_set && addingOPE"
                  >
                    <img
                      src="../assets/no-reports.svg"
                      alt=""
                      class="w-32 m-auto"
                    />
                    <p>It appears this assessment has no OPE report yet.</p>
                    <button
                      @click="addingOPE = false"
                      v-if="
                        assessment.status === 'pending' &&
                        !reportO.tasklevel_set &&
                        addingOPE &&
                        assessorType('ope') &&
                        assessment.status !== 'sealed'
                      "
                      class="font-medium mt-6 p-4 text-center mx-auto text-blue-600"
                    >
                      + Add Report
                    </button>
                  </div>
                  <table
                    v-if="reportO.tasklevel_set || !addingOPE"
                    class="w-full"
                  >
                    <thead>
                      <tr
                        class="text-md font-medium tracking-wide text-left text-t3 border-t"
                      >
                        <th class="px-4 py-3" width="12%"></th>
                        <th class="px-4 py-3 text-center">Pronunciation</th>
                        <th class="px-4 py-3 text-center">Structure</th>
                        <th class="px-4 py-3 text-center">Vocabulary</th>
                        <th class="px-4 py-3 text-center">Fluency</th>
                        <th class="px-4 py-3 text-center">Comprehension</th>
                        <th class="px-4 py-3 text-center">Interactions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="text-gray-700">
                        <td v-if="!addingOPE || !updateReport">Apply to All</td>
                        <td>
                          <select
                            v-if="!addingOPE || !updateReport"
                            @change="changeAllOPE"
                            name="pronunciation"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            v-if="!addingOPE || !updateReport"
                            @change="changeAllOPE"
                            name="structure"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            v-if="!addingOPE || !updateReport"
                            @change="changeAllOPE"
                            name="vocabulary"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            v-if="!addingOPE || !updateReport"
                            @change="changeAllOPE"
                            name="fluency"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            v-if="!addingOPE || !updateReport"
                            @change="changeAllOPE"
                            name="comprehension"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            v-if="!addingOPE || !updateReport"
                            @change="changeAllOPE"
                            name="interaction"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                      <tr
                        v-for="task in sortedTask()"
                        :key="task.id"
                        class="text-gray-700 border-t"
                      >
                        <td class="px-4 py-3 border-t text-md">
                          {{ task.name }}
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportOPETask(task.id)"
                            :disabled="addingOPE"
                            v-model="filterReportOPETask(task.id).pronunciation"
                            :class="{ disdrop: addingOPE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportOPETask(task.id)"
                            :disabled="addingOPE"
                            v-model="filterReportOPETask(task.id).structure"
                            :class="{ disdrop: addingOPE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportOPETask(task.id)"
                            :disabled="addingOPE"
                            v-model="filterReportOPETask(task.id).vocabulary"
                            :class="{ disdrop: addingOPE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportOPETask(task.id)"
                            :disabled="addingOPE"
                            v-model="filterReportOPETask(task.id).fluency"
                            :class="{ disdrop: addingOPE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportOPETask(task.id)"
                            :disabled="addingOPE"
                            v-model="filterReportOPETask(task.id).comprehension"
                            :class="{ disdrop: addingOPE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportOPETask(task.id)"
                            :disabled="addingOPE"
                            v-model="filterReportOPETask(task.id).interaction"
                            :class="{ disdrop: addingOPE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="flex w-full">
                    <button
                      v-if="!addingOPE || !updateReport"
                      @click="addReportOPE"
                      class="font-medium ml-auto mt-6 bg-t3 py-2 px-4 text-center mr-2 text-white rounded-md hover:text-white hover:bg-t3-secondary"
                    >
                      Update Task Levels
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>

          <!-- End OPE Card -->

          <!-- Start ELE Card -->
          <Transition name="slide-fade" appear>
            <div
              v-if="
                (betweenSet('ele') &&
                  (assessorType('ele') || assessorType('ope'))) ||
                scopeCheck('view_all_reports') ||
                checkSet()
              "
            >
              <div
                class="bg-white shadow-t3 rounded-t flex justify-between p-5 mt-6"
                type="button"
              >
                <div class="ml-4">
                  <div class="flex items-center text-md">
                    <div
                      v-if="currentELE"
                      class="relative w-10 h-10 mr-3 rounded-xl"
                    >
                      <img
                        class="object-cover w-full h-full rounded-xl"
                        :src="currentELE.profile_img"
                        loading="lazy"
                      />
                      <div
                        class="absolute inset-0 rounded-xl"
                        aria-hidden="true"
                      ></div>
                    </div>
                    <div v-if="currentELE">
                      <p class="font-medium text-t3">
                        {{ currentELE.full_name }}
                      </p>
                      <p class="text-xs text-gray-600">ELE</p>
                    </div>
                  </div>
                </div>
                <button
                  @click="addingELE = false"
                  v-if="
                    reportE.tasklevel_set &&
                    addingELE &&
                    assessorType('ele') &&
                    assessment.status !== 'sealed'
                  "
                  class="font-medium text-center ml-auto mr-6 text-blue-600"
                >
                  Update Report
                </button>
                <button
                  @click="cancelAddELE"
                  v-if="!addingELE && assessorType('ele')"
                  class="font-medium text-center ml-auto mr-6 text-t3-cancel"
                >
                  Cancel
                </button>
                <div
                  v-if="reportE.level"
                  class="mr-6 justify-between flex items-center"
                >
                  <div class="text-gray-600">
                    Report Level: {{ reportE.level }}
                  </div>
                </div>
              </div>
              <div
                id="eleTable"
                class="bg-white shadow-t3 rounded-b px-5 pb-4 pt-2 mb-6 overflow-x-scroll"
              >
                <div>
                  <div
                    class="text-xl w-full font-semibold text-center text-t3 my-24"
                    v-if="!reportE.tasklevel_set && addingELE"
                  >
                    <img
                      src="../assets/no-reports.svg"
                      alt=""
                      class="w-32 m-auto"
                    />
                    <p>It appears this assessment has no ELE Report yet.</p>
                    <button
                      @click="addingELE = false"
                      v-if="
                        assessment.status === 'pending' &&
                        !reportE.tasklevel_set &&
                        addingELE &&
                        assessorType('ele') &&
                        assessment.status !== 'sealed'
                      "
                      class="font-medium mt-6 p-4 text-center mx-auto text-blue-600"
                    >
                      + Add Report
                    </button>
                  </div>
                  <table
                    v-if="reportE.tasklevel_set || !addingELE"
                    class="w-full"
                  >
                    <thead>
                      <tr
                        class="text-md font-medium tracking-wide text-left text-t3 border-t"
                      >
                        <th class="px-4 py-3" width="12%"></th>
                        <th class="px-4 py-3 text-center">Pronunciation</th>
                        <th class="px-4 py-3 text-center">Structure</th>
                        <th class="px-4 py-3 text-center">Vocabulary</th>
                        <th class="px-4 py-3 text-center">Fluency</th>
                        <th class="px-4 py-3 text-center">Comprehension</th>
                        <th class="px-4 py-3 text-center">Interactions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="text-gray-700">
                        <td v-if="!addingELE || !updateReport">Apply to All</td>
                        <td>
                          <select
                            v-if="!addingELE || !updateReport"
                            @change="changeAllELE"
                            name="pronunciation"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            v-if="!addingELE || !updateReport"
                            @change="changeAllELE"
                            name="structure"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            v-if="!addingELE || !updateReport"
                            @change="changeAllELE"
                            name="vocabulary"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            v-if="!addingELE || !updateReport"
                            @change="changeAllELE"
                            name="fluency"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            v-if="!addingELE || !updateReport"
                            @change="changeAllELE"
                            name="comprehension"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td>
                          <select
                            v-if="!addingELE || !updateReport"
                            @change="changeAllELE"
                            name="interaction"
                            class="my-6 mx-auto form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null">-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                      <tr
                        v-for="task in sortedTask()"
                        :key="task.id"
                        class="text-gray-700 border-t"
                      >
                        <td class="px-4 py-3 border-t text-md">
                          {{ task.name }}
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportELETask(task.id)"
                            :disabled="addingELE"
                            v-model="filterReportELETask(task.id).pronunciation"
                            :class="{ disdrop: addingELE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportELETask(task.id)"
                            :disabled="addingELE"
                            v-model="filterReportELETask(task.id).structure"
                            :class="{ disdrop: addingELE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportELETask(task.id)"
                            :disabled="addingELE"
                            v-model="filterReportELETask(task.id).vocabulary"
                            :class="{ disdrop: addingELE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportELETask(task.id)"
                            :disabled="addingELE"
                            v-model="filterReportELETask(task.id).fluency"
                            :class="{ disdrop: addingELE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportELETask(task.id)"
                            :disabled="addingELE"
                            v-model="filterReportELETask(task.id).comprehension"
                            :class="{ disdrop: addingELE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                        <td class="px-4 py-3 border-t text-md text-center">
                          <select
                            v-if="filterReportELETask(task.id)"
                            :disabled="addingELE"
                            v-model="filterReportELETask(task.id).interaction"
                            :class="{ disdrop: addingELE }"
                            class="m-auto text-center form-control block w-16 p-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                          >
                            <option :value="null" disabled>-</option>
                            <option value="6">6</option>
                            <option value="5">5</option>
                            <option value="4">4</option>
                            <option value="3">3</option>
                            <option value="2">2</option>
                            <option value="1">1</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="flex w-full">
                    <button
                      v-if="!addingELE"
                      @click="addReportELE"
                      class="font-medium ml-auto mt-6 bg-t3 py-2 px-4 text-center mr-2 text-white rounded-md hover:text-white hover:bg-t3-secondary"
                    >
                      Update Task Levels
                    </button>
                  </div>
                </div>

                <!-- <p>{{reporELE}}</p> -->
                <!-- End ELE Card -->
              </div>
            </div>
          </Transition>
        </div>
        <!-- Start Right Side Card -->
        <div
          v-if="
            scopeCheck('view_assessment_comments') ||
            scopeCheck('view_assessment_recordings') ||
            ((assessment.status === 'completed' ||
              settings.show_sample_before_completion) &&
              scopeCheck('view_assessment_sample') &&
              assessment.sample)
          "
        >
          <div class="justify-between">
            <ul
              class="nav nav-pills flex flex-col md:flex-row flex-wrap list-none pl-0 mb-4"
              id="pills-tab"
              role="tablist"
            >
              <li
                v-if="scopeCheck('view_assessment_recordings')"
                class="nav-item"
                role="presentation"
              >
                <a
                  href="#pills-home"
                  class="nav-link block font-medium text-md leading-tight rounded px-6 py-3 my-2 md:mr-2 focus:outline-none focus:ring-0 active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  >Attachments</a
                >
              </li>
              <li
                v-if="scopeCheck('view_assessment_comments')"
                class="nav-item"
                role="presentation"
              >
                <a
                  href="#pills-profile"
                  class="nav-link block font-medium text-md leading-tight rounded px-6 py-3 my-2 md:mx-2 focus:outline-none focus:ring-0"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  >Comments</a
                >
              </li>
              <li
                class="nav-item"
                role="presentation"
                v-if="
                  (assessment.status === 'completed' ||
                    settings.show_sample_before_completion) &&
                  scopeCheck('view_assessment_sample') &&
                  assessment.sample
                "
              >
                <a
                  href="#pills-contact"
                  class="nav-link block font-medium text-md leading-tight rounded px-6 py-3 my-2 md:mx-2 focus:outline-none focus:ring-0"
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-sample"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                  >Sample</a
                >
              </li>
            </ul>
          </div>
          <div class="bg-white shadow-t3 p-5">
            <div
              v-if="loading"
              class="flex flex-col w-full items-center justify-center"
            >
              <p class="text-t3 text-xs text-center">Loading please hold</p>
              <PulseLoader
                :loading="loading"
                color="#00ADDEE"
                size="7px"
              ></PulseLoader>
            </div>
            <div v-if="!loading" class="tab-content" id="pills-tabContent">
              <div
                v-if="scopeCheck('view_assessment_recordings')"
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div class="flex w-full justify-end">
                  <span
                    v-if="
                      !addingRec &&
                      this.assessmendRecordings.length < this.tasks.length &&
                      !isCompany &&
                      assessment.status !== 'sealed'
                    "
                    class="font-medium text-center mr-2 text-blue-600 cursor-pointer mb-2"
                    @click="addingRec = true"
                  >
                    + Add
                  </span>
                  <span
                    v-if="addingRec"
                    class="font-medium text-center mr-2 text-t3-cancel cursor-pointer mb-2"
                    @click="attachmentCancel()"
                  >
                    Cancel</span
                  >
                </div>
                <div
                  class="text-xl w-full font-semibold text-center text-t3 my-24"
                  v-if="assessmendRecordings.length === 0 && !addingRec"
                >
                  <img
                    src="../assets/no-attach.svg"
                    alt=""
                    class="w-24 m-auto"
                  />
                  <p>It appears this assessment has no recordings</p>
                </div>
                <form
                  v-if="addingRec"
                  ref="addImageForm"
                  class="text-center flex- flex-col items-center justify-center w-full"
                >
                  <label
                    class="w-full flex flex-col items-center px-4 py-12 bg-white text-blue rounded-lg tracking-wide border border-gray-300 border-dashed cursor-pointer hover:border-t3-teritiary hover:text-white-teritiary"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.0"
                      stroke="currentColor"
                      class="w-12 h-12"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                      />
                    </svg>
                    <span class="mt-2 text-base leading-normal"
                      >Select a file</span
                    >
                    <FileInput
                      multiple="multiple"
                      id="inputfield"
                      ref="fileupload"
                      accept="audio/*"
                      @change="attachmentLoad"
                      v-model="attachments"
                      class="hidden"
                    />
                  </label>

                  <div class="my-4">
                    <div
                      class="flex justify-between w-full items-center"
                      v-for="(record, index) in attachments"
                      :key="index"
                    >
                      <p class="w-44 truncate">{{ record.recording.name }}</p>
                      <PulseLoader
                        :loading="record.loader === 'load'"
                        color="#0E2D5B"
                        size="7px"
                      ></PulseLoader>
                      <select
                        v-if="record.loader === 'no'"
                        v-model="record.task"
                        class="my-2 form-control block w-44 p-3 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                      >
                        <option :value="null" disabled>
                          Please select a task to add the attachment to
                        </option>
                        <option
                          v-for="task in sortedTask()"
                          :disabled="
                            attachments.find((a) => a.task === task.id) ||
                            assessmendRecordings.find((a) => a.task === task.id)
                          "
                          :key="task.id"
                          :value="task.id"
                        >
                          {{ task.name }}
                        </option>
                      </select>
                      <p
                        v-if="record.loader === 'yes'"
                        class="text-sm text-t3-secondary"
                      >
                        Done
                      </p>
                    </div>
                  </div>
                  <button
                    type="submit"
                    @click="submitAttachment"
                    class="font-medium bg-t3 p-2 text-center w-full text-white rounded-md hover:text-white hover:bg-t3-secondary"
                  >
                    Send
                  </button>
                </form>
                <div v-if="!loading" class="my-6">
                  <div v-for="task in sortedTask()" :key="task.id">
                    <div
                      v-if="filteredRecordings(task.id).length !== 0"
                      class="flex hover:bg-gray-100 w-full rounded px-2 justify-between"
                    >
                      <button
                        @click="changeURL"
                        v-for="recording in filteredRecordings(task.id)"
                        class="text-t3 flex gap-2 items-center"
                        :key="recording.id"
                        :name="task.name"
                        :value="recording.recording"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.2"
                          stroke="currentColor"
                          class="w-5 h-5"
                          :class="{
                            'text-t3-secondary':
                              AudioAttrs.src === recording.recording,
                          }"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
                          />
                        </svg>
                        <h2 class="text-md my-2">{{ task.name }} Task</h2>
                      </button>
                      <button v-if="scopeCheck('archive_assessment_recordings')" @click="archiveRecording(filteredRecordings(task.id)[0].id)" class="mr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-t3-cancel w-5 h-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    recordings.find((cus) => cus.assessment === parseInt(id)) &&
                    AudioAttrs.src
                  "
                  class="border-t items-center py-3 flex w-full"
                >
                  <span
                    @click="playButtn()"
                    class="ml-12 border rounded-full border-t3 p-1 cursor-pointer text-t3"
                  >
                    <svg
                      v-if="!buttonP"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 ml-1 my-0.5 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                      />
                    </svg>
                    <svg
                      v-if="buttonP"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 mx-0.5 my-0.5 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                      />
                    </svg>
                  </span>
                  <AudioPlayer
                    :option="AudioAttrs"
                    @play="buttonP = true"
                    @pause="buttonP = false"
                    class="w-full mr-12"
                  />
                </div>
              </div>
              <div
                v-if="
                  assessorType('ele') ||
                  assessorType('ope') ||
                  scopeCheck('view_assessment_comments') ||
                  checkSet()
                "
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div
                  v-if="betweenSet('ope')"
                  class="flex justify-between mt-2"
                  type="button"
                >
                  <div v-if="betweenSet('ope')" class="ml-4">
                    <div v-if="currentOPE" class="flex items-center text-md">
                      <div class="relative w-10 h-10 mr-3 rounded-xl">
                        <img
                          class="object-cover w-full h-full rounded-xl"
                          :src="currentOPE.profile_img"
                          loading="lazy"
                        />
                        <div
                          class="absolute inset-0 rounded-xl"
                          aria-hidden="true"
                        ></div>
                      </div>
                      <div>
                        <p v-if="currentOPE" class="font-medium text-t3">
                          {{ currentOPE.full_name }}
                        </p>
                        <p class="text-xs text-gray-600">OPE</p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="betweenSet('ope')"
                    class="mr-6 justify-between flex items-center"
                  >
                    <div class="text-gray-600 flex gap-6">
                      <button
                        v-if="
                          addingOPEComment &&
                          assessorType('ope') &&
                          assessment.status !== 'sealed'
                        "
                        @click="addingOPEComment = false"
                        class="font-medium py-2 px-4 text-center mr-2 text-t3-teritiary hover:cursor-pointer"
                      >
                        Edit
                      </button>
                      <button
                        v-if="!addingOPEComment"
                        @click="addReportOPEComment()"
                        class="font-medium py-2 px-4 text-center mr-2 text-t3-secondary hover:cursor-pointer"
                      >
                        Save Changes
                      </button>
                      <button
                        v-if="!addingOPEComment"
                        @click="addingOPEComment = true"
                        class="font-medium py-2 px-4 text-center mr-2 text-t3-cancel hover:cursor-pointer"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="betweenSet('ope')" class="p-5">
                  <div
                    class="text-sm w-full text-center text-t3 my-6"
                    v-if="!reportO.comment && addingOPEComment"
                  >
                    <img
                      src="../assets/no-reports.svg"
                      alt=""
                      class="w-16 m-auto"
                    />
                    <p>It appears this assessment has no ope comment</p>
                  </div>
                  <div
                    v-if="!addingOPEComment"
                    class="flex space-x-3 w-full items-center"
                  >
                    <textarea
                      v-model="commentOPE"
                      :class="{ disdrop: addingOPEComment }"
                      class="form-control block w-full px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="Prefix"
                      placeholder="Report comment goes here"
                    ></textarea>
                    <!-- <input type="text" v-model="comment" class="form-control block w-1/2 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="Prefix" placeholder="Report comment goes here"/> -->
                  </div>
                  <p v-if="addingOPEComment" class="ml-4 text-md text-gray-600">
                    {{ reportO.comment }}
                  </p>
                </div>
                <hr
                  v-if="betweenSet('ele') && betweenSet('ope')"
                  class="my-4"
                />
                <div
                  v-if="betweenSet('ele')"
                  class="flex justify-between mt-2"
                  type="button"
                >
                  <div v-if="betweenSet('ele')" class="ml-4">
                    <div class="flex items-center text-md">
                      <div
                        v-if="currentELE"
                        class="relative w-10 h-10 mr-3 rounded-xl"
                      >
                        <img
                          class="object-cover w-full h-full rounded-xl"
                          :src="currentELE.profile_img"
                          loading="lazy"
                        />
                        <div
                          class="absolute inset-0 rounded-xl"
                          aria-hidden="true"
                        ></div>
                      </div>
                      <div>
                        <p v-if="currentELE" class="font-medium text-t3">
                          {{ currentELE.full_name }}
                        </p>
                        <p class="text-xs text-gray-600">ELE</p>
                      </div>
                    </div>
                  </div>
                  <div class="mr-6 justify-between flex items-center">
                    <div class="2xl:flex gap-6 text-gray-600">
                      <button
                        v-if="
                          addingELEComment &&
                          assessorType('ele') &&
                          assessment.status !== 'sealed'
                        "
                        @click="addingELEComment = false"
                        class="font-medium py-2 px-4 text-center mr-2 text-t3-teritiary hover:cursor-pointer"
                      >
                        Edit
                      </button>
                      <button
                        v-if="!addingELEComment"
                        @click="addReportELEComment()"
                        class="font-medium block py-2 px-4 text-center mr-2 text-t3-secondary hover:cursor-pointer"
                      >
                        Save Changes
                      </button>
                      <button
                        v-if="!addingELEComment"
                        @click="addingELEComment = true"
                        class="font-medium block py-2 px-4 text-center mr-2 text-t3-cancel hover:cursor-pointer"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="betweenSet('ele')" class="p-5">
                  <div
                    class="text-sm w-full text-center text-t3 my-6"
                    v-if="!reportE.comment && addingELEComment"
                  >
                    <img
                      src="../assets/no-reports.svg"
                      alt=""
                      class="w-16 m-auto"
                    />
                    <p>It appears this assessment has no ele comment</p>
                  </div>
                  <div
                    v-if="!addingELEComment"
                    class="flex space-x-3 w-full items-center"
                  >
                    <textarea
                      v-model="commentELE"
                      :class="{ disdrop: addingELEComment }"
                      class="form-control block w-full px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="Prefix"
                      placeholder="Report comment goes here"
                    ></textarea>
                    <!-- <input type="text" v-model="comment" class="form-control block w-1/2 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="Prefix" placeholder="Report comment goes here"/> -->
                  </div>
                  <p v-if="addingELEComment" class="ml-4 text-md text-gray-600">
                    {{ reportE.comment }}
                  </p>
                </div>
                <hr
                  v-if="
                    (betweenSet('ele') || betweenSet('ope')) &&
                    scopeCheck('view_admin_comment')
                  "
                  class="my-4"
                />
                <div
                  v-if="scopeCheck('view_admin_comment')"
                  class="flex justify-between mt-2"
                  type="button"
                >
                  <div class="ml-4">
                    <div class="flex items-center text-md">
                      <div
                        v-if="assessment.admin_comment"
                        class="relative w-10 h-10 mr-3 rounded-xl"
                      >
                        <img
                          class="object-cover w-full h-full rounded-xl"
                          :src="assessment.admin_comment.customer.profile_image"
                          loading="lazy"
                        />
                        <div
                          class="absolute inset-0 rounded-xl"
                          aria-hidden="true"
                        ></div>
                      </div>
                      <div>
                        <p
                          v-if="assessment.admin_comment"
                          class="font-medium text-t3"
                        >
                          {{ assessment.admin_comment.customer.full_name }}
                        </p>
                        <p class="text-xs text-gray-600">Senior Assessor</p>
                      </div>
                    </div>
                  </div>
                  <div class="mr-6 justify-between flex items-center">
                    <div class="2xl:flex gap-6 text-gray-600">
                      <button
                        v-if="
                          addingAdminComment &&
                          scopeCheck('write_admin_comment') &&
                          assessment.status !== 'sealed'
                        "
                        @click="addingAdminComment = false"
                        class="font-medium py-2 px-4 text-center mr-2 text-t3-teritiary hover:cursor-pointer"
                      >
                        Edit
                      </button>
                      <button
                        v-if="!addingAdminComment && !assessment.admin_comment"
                        @click="addAdminComment()"
                        class="font-medium block py-2 px-4 text-center mr-2 text-t3-secondary hover:cursor-pointer"
                      >
                        Save Changes
                      </button>
                      <button
                        v-if="!addingAdminComment && assessment.admin_comment"
                        @click="updateAdminComment()"
                        class="font-medium block py-2 px-4 text-center mr-2 text-t3-secondary hover:cursor-pointer"
                      >
                        Save Changes
                      </button>
                      <button
                        v-if="!addingAdminComment"
                        @click="addingAdminComment = true"
                        class="font-medium block py-2 px-4 text-center mr-2 text-t3-cancel hover:cursor-pointer"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="scopeCheck('view_admin_comment')" class="p-5">
                  <div
                    class="text-sm w-full text-center text-t3 my-6"
                    v-if="!assessment.admin_comment && addingAdminComment"
                  >
                    <img
                      src="../assets/no-reports.svg"
                      alt=""
                      class="w-16 m-auto"
                    />
                    <p>
                      It appears this assessment has no Senior Assessor
                      comment!!
                    </p>
                  </div>
                  <div
                    v-if="!addingAdminComment"
                    class="flex space-x-3 w-full items-center"
                  >
                    <textarea
                      v-model="commentAdmin"
                      :class="{ disdrop: addingAdminComment }"
                      class="form-control block w-full px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      id="Prefix"
                      placeholder="Admin comment goes here"
                    ></textarea>
                    <!-- <input type="text" v-model="comment" class="form-control block w-1/2 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="Prefix" placeholder="Report comment goes here"/> -->
                  </div>
                  <p
                    v-if="addingAdminComment && assessment.admin_comment"
                    class="ml-4 text-md text-gray-600"
                  >
                    {{ assessment.admin_comment.comment }}
                  </p>
                </div>

                <!-- End of third Assessor comment -->
                <router-link
                  v-if="reportO.comment && reportE.comment"
                  :to="{
                    name: 'AssessmentReport',
                    params: { id: assessment.id },
                  }"
                >
                  <div class="block text-center items-center p-3">
                    <img
                      src="../assets/certificate.svg"
                      class="h-10 w-10 m-auto object-cover"
                    />
                    <p class="text-t3 text-center">Report</p>
                  </div>
                </router-link>
              </div>

              <div
                class="tab-pane fade"
                id="pills-sample"
                role="tabpanel"
                aria-labelledby="pills-sample-tab"
                v-if="
                  (assessment.status === 'completed' ||
                    settings.show_sample_before_completion) &&
                  scopeCheck('view_assessment_sample') &&
                  assessment.sample
                "
              >
                <div class="bg-white p-2 rounded">
                  <ul
                    class="nav nav-tabs flex justify-center flex-row flex-wrap list-none border-b-0 pl-0 mb-4"
                    id="tabs-tab"
                    role="tablist"
                  >
                    <li
                      v-for="(task, index) in sortedTask()"
                      :key="task.id"
                      class="nav-item"
                      role="presentation"
                    >
                      <button
                        :href="'#a' + task.id"
                        :value="task.id"
                        class="nav-link block font-medium leading-tight text-base border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent"
                        :id="'tabs-' + task.id + '-tab'"
                        data-bs-toggle="pill"
                        :data-bs-target="'#a' + task.id"
                        role="tab"
                        :aria-controls="'tabs-' + task.id"
                        aria-selected="true"
                        :class="{ active: index === 0 }"
                      >
                        {{ task.id }}
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="tabs-tabContent">
                    <div
                      v-for="(task, index) in sortedTask()"
                      :key="index"
                      class="tab-pane fade"
                      :class="{ 'active show': index === 0 }"
                      :id="'a' + task.id"
                      role="tabpanel"
                      aria-labelledby="tabs-text-tab"
                    >
                      <div>
                        <ul>
                          <h2 class="text-center text-t3">{{ task.name }}</h2>
                          <li
                            class="text-xl font-semibold text-center text-t3 my-64"
                            v-if="questionTask(task.id).length === 0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-12 w-12 mx-auto my-2"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              stroke-width="1.5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <p>It appears there are no questions here yet.</p>
                          </li>
                          <li
                            class="text-xl font-semibold text-t3 my-8"
                            v-if="
                              questionFilter(questionTask(task.id), 'txt')
                                .length !== 0
                            "
                          ></li>
                          <li
                            class="border-b p-3 border-t3-body"
                            v-for="question in questionFilter(
                              questionTask(task.id),
                              'txt'
                            )"
                            :key="question.id"
                          >
                            <span v-if="question.type === 'txt'">{{
                              question.text
                            }}</span>
                          </li>
                          <li
                            class="text-xl font-semibold text-t3 my-8"
                            v-if="
                              questionFilter(questionTask(task.id), 'img')
                                .length !== 0
                            "
                          ></li>
                          <li class="grid w-full md:grid-cols-2">
                            <div
                              class="border-b p-3 border-t3-body"
                              v-for="question in questionFilter(
                                questionTask(task.id),
                                'img'
                              )"
                              :key="question.id"
                            >
                              <a :href="question.content" target="_blank">
                                <img
                                  class="w-44 object-cover rounded hover:drop-shadow-xl hover:scale-150"
                                  v-if="question.type == 'img'"
                                  :src="question.content"
                                  :alt="question.description"
                                />
                              </a>
                            </div>
                          </li>
                          <li
                            class="text-xl font-semibold text-t3 my-8"
                            v-if="
                              questionFilter(questionTask(task.id), 'audio')
                                .length !== 0
                            "
                          ></li>
                          <li>
                            <div
                              class="w-full flex flex-col gap-3 items-center text-md text-gray-700 border-b p-3 border-t3-body"
                              v-for="question in questionFilter(
                                questionTask(task.id),
                                'audio'
                              )"
                              :key="question.id"
                            >
                              <audio
                                class="w-full"
                                :src="question.content"
                                controls
                              >
                                <source type="audio/mp3" />
                              </audio>
                              <p>{{ question.description }}</p>
                            </div>
                          </li>
                          <li
                            class="text-xl font-semibold text-t3 my-8"
                            v-if="
                              questionFilter(questionTask(task.id), 'pdf')
                                .length !== 0
                            "
                          ></li>
                          <li>
                            <div
                              class="border-b p-3 border-t3-body"
                              v-for="question in questionFilter(
                                questionTask(task.id),
                                'pdf'
                              )"
                              :key="question.id"
                            >
                              <a :href="question.content" download
                                >Download {{ question.description }}</a
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="scopeCheck('view_assessments_audit_trail')"
            class="flex w-full justify-center mt-12 text-t3"
          >
            <button
              type="button"
              class="font-semibold"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              - View Logs -
            </button>
          </div>

          <div
            class="offcanvas offcanvas-end fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-96"
            tabindex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div class="offcanvas-header flex items-center justify-between p-4">
              <h5
                class="offcanvas-title mb-0 leading-normal font-semibold"
                id="offcanvasRightLabel"
              >
                Assessment Logs
              </h5>
              <button
                type="button"
                class="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body flex-grow p-4 overflow-y-auto">
              <div class="overflow-auto">
                <div v-for="log in mainLogs" :key="log.id">
                  <div class="flex justify-between">
                    <div
                      v-if="
                        allStaff.find(
                          (customer) => customer.email === log.user_email
                        )
                      "
                      class="my-2"
                    >
                      <p class="font-medium text-sm text-t3">
                        <span class="font-semibold">{{
                          allStaff.find(
                            (customer) => customer.email === log.user_email
                          ).full_name
                        }}</span>
                        {{ logMessage(log.method) }}
                      </p>
                      <p class="font-medium text-sm text-gray-500">
                        {{
                          new Date(log.datetime).toLocaleDateString("en-GB", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })
                        }}
                        at
                        {{
                          new Date(log.datetime).toLocaleTimeString("en", {
                            timeStyle: "short",
                            hour12: false,
                          })
                        }}
                      </p>
                    </div>
                    <div v-if="log.method === 'SEAL_ASSESSMENT'">
                      <p class="font-medium text-sm text-t3 font-semibold">
                        {{ logMessage(log.method) }}
                      </p>
                      <p class="font-medium text-sm text-gray-500">
                        {{
                          new Date(log.datetime).toLocaleDateString("en-GB", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })
                        }}
                        at
                        {{
                          new Date(log.datetime).toLocaleTimeString("en", {
                            timeStyle: "short",
                            hour12: false,
                          })
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <p
                  class="mt-12 mb-6 cursor-pointer text-t3-teritiary border-b pb-3"
                  @click="vvlogs = !vvlogs"
                >
                  Toggle View Logs
                </p>
                <div v-if="vvlogs === true">
                  <div v-for="log in viewLogs" :key="log.id">
                    <div class="flex justify-between">
                      <div
                        v-if="
                          allStaff.find(
                            (customer) => customer.email === log.user_email
                          )
                        "
                        class="my-2"
                      >
                        <p class="font-medium text-sm text-t3">
                          <span class="font-semibold"
                            >{{
                              allStaff.find(
                                (customer) => customer.email === log.user_email
                              ).full_name
                            }}
                          </span>
                          viewed this assessment
                        </p>
                        <p class="font-medium text-sm text-gray-500">
                          {{
                            new Date(log.datetime).toLocaleDateString("en-GB", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })
                          }}
                          at
                          {{
                            new Date(log.datetime).toLocaleTimeString("en", {
                              timeStyle: "short",
                              hour12: false,
                            })
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Right Side Card -->
      </div>
    </div>
    <!-- End Reports and Additional Info Card -->
  </Transition>
</template>

<style scoped>
.nav-pills .nav-link.active {
  background: white;
  color: #0e2d5b;
  font-weight: bold;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
}

.nav-pills .nav-link {
  background: none;
  color: #4b5563;
  box-shadow: none;
}
.disdrop {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  border: none;
}
</style>
<style>
.audio__player-play {
  display: none !important;
}
</style>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import FileInput from "@/components/MultiFileInput.vue";
import RecordingService from "../services/RecordingServices";
import AssessmentService from "../services/AssessmentsServices";
import { watchEffect } from "vue";
import Swal from "sweetalert2";
import ReportServices from "@/services/ReportServices";
import AudioPlayer from "vue3-audio-player";
import "vue3-audio-player/dist/style.css";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import router from "../router";

export default {
  data() {
    return {
      buttonP: false,
      loading: false,
      loadinger: true,
      vvlogs: false,
      addingRec: false,
      commentELE: null,
      commentOPE: null,
      commentAdmin: null,
      updateReport: true,
      sampleCheck: true,
      sendOPE: null,
      AudioAttrs: {
        src: "",
        title: "Select an audio to play",
        coverRotate: false,
        progressBarColor: "#FCBD0B",
        indicatorColor: "0E2D5B",
      },
      users: [],
      booking: true,
      attachments: [],
      addingOPE: true,
      addingOPEComment: true,
      reportOPE: {
        tasks: [],
      },
      addingELE: true,
      addingELEComment: true,
      reportELE: {
        tasks: [],
      },
      addingAdmin: true,
      addingAdminComment: true,
      cancelReason: null,
      disputeReason: null,
      staffReason: null,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    FileInput,
    AudioPlayer,
    PulseLoader,
  },

  methods: {
    ...mapActions({
      getTasks: "Assessments/getAllTasks",
      getassessment: "Assessments/getAssessment",
      getassessmentLogs: "Assessments/getAssessmentLogs",
      cancelassessment: "Assessments/cancelAssessment",
      getreportELE: "Assessments/getELEReport",
      getreportOPE: "Assessments/getOPEReport",
      getcustomer: "Users/getCustomer",
      getstaff: "Users/getAllStaff",
      getrecordings: "Recordings/getRecordings",
      getAssessmentRecordings: "Recordings/getAssessmentRecordings",
      getquestions: "Questions/getAllQuestions",
      getsample: "Samples/getSample",
      deletesample: "Samples/deleteSample",
      getsettings: "Settings/getAllSettings",
    }),

    onGo() {
      if (this.assessment.status === "ongoing") {
        router.push({
          name: "AssessmentStarted",
          params: { id: this.assessment.id },
        });
      } else {
        this.deletesample().then(() => {
          AssessmentService.onGoing(this.assessment.id)
            .then(() => {
              router.push({
                name: "AssessmentStarted",
                params: { id: this.assessment.id },
              });
            })
            .catch((error) => {
              let message =
                Object.keys(error.response.data).at(0) +
                " : " +
                error.response.data[Object.keys(error.response.data).at(0)];
              Swal.fire({
                icon: "error",
                title: "It appears you have encountered an error!",
                text: message,
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonColor: "#0E2D5B",
                denyButtonColor: "#990c53",
                denyButtonText: "Ignore",
                confirmButtonText: "Send Report",
              }).then((result) => {
                if (result.value) {
                  let user = JSON.parse(localStorage.getItem("userdetail"));
                  ReportServices.sendErrorReport({
                    error_page: window.location.href,
                    error_api: error.response.config.url,
                    error_message: message,
                    email: user.email,
                  });
                }
              });
            });
        });
      }
    },
    checkSet() {
      let user = JSON.parse(localStorage.getItem("userdetail"));
      if (
        (user.id === this.settings.internal_dispute ||
          user.id === this.settings.external_dispute ||
          user.id === this.settings.dispute_final_approver) &&
        !user.is_company
      ) {
        return true;
      } else {
        return false;
      }
    },
    assessorType(type) {
      let user = JSON.parse(localStorage.getItem("userdetail"));
      if (this.assessment[type] === user.id) {
        return true;
      } else {
        return false;
      }
    },
    betweenSet(type) {
      if (
        (this.settings.show_reports_to_assessors &&
          (this.assessorType("ope") || this.assessorType("ele"))) ||
        this.scopeCheck("view_all_reports") ||
        this.checkSet()
      ) {
        return true;
      } else {
        return this.assessorType(type);
      }
    },
    changeAllOPE(event) {
      this.reportOPE.tasks.forEach((t) => {
        t[event.target.name] = event.target.value;
      });
    },
    changeAllELE(event) {
      this.reportELE.tasks.forEach((t) => {
        t[event.target.name] = event.target.value;
      });
    },
    expirydate() {
      let date = new Date(this.assessment.completion_time);
      switch (this.assessment.final_level) {
        case 6:
          date = "Lifetime";
          break;
        case 5:
          date.setFullYear(date.getFullYear() + 6);
          date.setDate(date.getDate() - 1);
          break;
        case 4:
          date.setFullYear(date.getFullYear() + 3);
          date.setDate(date.getDate() - 1);
          break;
      }
      if (this.assessment.final_level === 6) {
        return date;
      } else {
        return date.toLocaleDateString("en-GB", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      }
    },
    playButtn() {
      document.getElementsByClassName("audio__player-play")[0].click();
    },
    reqSend() {
      if (this.disputeReason && this.staffReason) {
        let sendingObj = {
          reason_of_staff: this.staffReason,
          reason_of_dispute: this.disputeReason,
          type: "external",
        };
        const getFormData = (object) =>
          Object.entries(object).reduce((fd, [key, val]) => {
            if (Array.isArray(val)) {
              val.forEach((v) => fd.append(key, v));
            } else {
              fd.append(key, val);
            }
            return fd;
          }, new FormData());
        AssessmentService.raiseDispute(
          this.assessment.id,
          getFormData(sendingObj)
        )
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Dispute raised!",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 3000,
            });
            this.disputeReason = null;
            this.$forceUpdate();
            document.getElementById("disputeClose").click();
          })
          .catch((error) => {
            let message =
              Object.keys(error.response.data).at(0) +
              " : " +
              error.response.data[Object.keys(error.response.data).at(0)];
            Swal.fire({
              icon: "error",
              title: "It appears you have encountered an error!",
              text: message,
              showConfirmButton: true,
              showDenyButton: true,
              confirmButtonColor: "#0E2D5B",
              denyButtonColor: "#990c53",
              denyButtonText: "Ignore",
              confirmButtonText: "Send Report",
            }).then((result) => {
              if (result.value) {
                let user = JSON.parse(localStorage.getItem("userdetail"));
                ReportServices.sendErrorReport({
                  error_page: window.location.href,
                  error_api: error.response.config.url,
                  error_message: message,
                  email: user.email,
                });
              }
            });
          });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Hold",
          text: "Fill in all the required fields first",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
      }
    },
    cancelled() {
      if (this.cancelReason) {
        let reasononing = {
          reason: this.cancelReason,
        };
        const getFormData = (object) =>
          Object.entries(object).reduce((fd, [key, val]) => {
            if (Array.isArray(val)) {
              val.forEach((v) => fd.append(key, v));
            } else {
              fd.append(key, val);
            }
            return fd;
          }, new FormData());
        AssessmentService.cancelAssessment(
          this.assessment.id,
          getFormData(reasononing)
        )
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "The assessment was cancelled.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 3000,
            });
            this.$router.go();
          })
          .catch((error) => {
            let message =
              Object.keys(error.response.data).at(0) +
              " : " +
              error.response.data[Object.keys(error.response.data).at(0)];
            Swal.fire({
              icon: "error",
              title: "It appears you have encountered an error!",
              text: message,
              showConfirmButton: true,
              showDenyButton: true,
              confirmButtonColor: "#0E2D5B",
              denyButtonColor: "#990c53",
              denyButtonText: "Ignore",
              confirmButtonText: "Send Report",
            }).then((result) => {
              if (result.value) {
                let user = JSON.parse(localStorage.getItem("userdetail"));
                ReportServices.sendErrorReport({
                  error_page: window.location.href,
                  error_api: error.response.config.url,
                  error_message: message,
                  email: user.email,
                });
              }
            });
          });
      }
    },
    changeURL(event) {
      this.AudioAttrs.src = event.currentTarget.value;
      this.AudioAttrs.title = event.currentTarget.name;
    },
    cancelAddOPE() {
      this.addingOPE = true;
      if (!this.reportO.tasklevel_set) {
        this.reportOPE.tasks.forEach((t) => {
          t.comprehension = null;
          t.structure = null;
          t.fluency = null;
          t.interaction = null;
          t.pronunciation = null;
          t.vocabulary = null;
        });
      }
    },

    filterReportOPETask(id) {
      return this.reportOPE.tasks.find((t) => t.task === id);
    },
    filterReportELETask(id) {
      return this.reportELE.tasks.find((t) => t.task === id);
    },
    addReportOPEComment() {
      if (this.commentOPE) {
        AssessmentService.addReportComment(this.reportO.id, {
          comment: this.commentOPE,
        })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Report comment added successfully!",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 3000,
            });
            this.addingOPEComment = true;
            this.getreportOPE(this.id);
            this.$forceUpdate;
          })
          .catch((error) => {
            let message =
              Object.keys(error.response.data).at(0) +
              " : " +
              error.response.data[Object.keys(error.response.data).at(0)];
            Swal.fire({
              icon: "error",
              title: "It appears you have encountered an error!",
              text: message,
              showConfirmButton: true,
              showDenyButton: true,
              confirmButtonColor: "#0E2D5B",
              denyButtonColor: "#990c53",
              denyButtonText: "Ignore",
              confirmButtonText: "Send Report",
            }).then((result) => {
              if (result.value) {
                let user = JSON.parse(localStorage.getItem("userdetail"));
                ReportServices.sendErrorReport({
                  error_page: window.location.href,
                  error_api: error.response.config.url,
                  error_message: message,
                  email: user.email,
                });
              }
            });
          });
      }
    },
    addReportOPE() {
      let checkFull = true;

      this.reportOPE.tasks.forEach((t) => {
        if (
          t.comprehension === null ||
          t.fluency === null ||
          t.interaction === null ||
          t.structure === null ||
          t.pronunciation === null ||
          t.vocabulary === null
        ) {
          checkFull = false;
        }
      });

      if (checkFull) {
        if (!this.reportO.tasklevel_set) {
          AssessmentService.addReportAll(this.id, this.reportOPE.tasks)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "This report has been added!",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
              });
              this.addingOPE = true;
              this.getassessment(this.id);
              this.getreportOPE(this.id);
              this.$forceUpdate;
            })
            .catch((error) => {
              let message =
                Object.keys(error.response.data).at(0) +
                " : " +
                error.response.data[Object.keys(error.response.data).at(0)];
              Swal.fire({
                icon: "error",
                title: "It appears you have encountered an error!",
                text: message,
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonColor: "#0E2D5B",
                denyButtonColor: "#990c53",
                denyButtonText: "Ignore",
                confirmButtonText: "Send Report",
              }).then((result) => {
                if (result.value) {
                  let user = JSON.parse(localStorage.getItem("userdetail"));
                  ReportServices.sendErrorReport({
                    error_page: window.location.href,
                    error_api: error.response.config.url,
                    error_message: message,
                    email: user.email,
                  });
                }
              });
            });
        } else {
          AssessmentService.updateReport(this.reportO.id, this.reportOPE.tasks)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "This report has been updated!",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
              });
              this.addingOPE = true;
              this.getassessment(this.id);
              this.getreportOPE(this.id);
              this.$forceUpdate;
            })
            .catch((error) => {
              let message =
                Object.keys(error.response.data).at(0) +
                " : " +
                error.response.data[Object.keys(error.response.data).at(0)];
              Swal.fire({
                icon: "error",
                title: "It appears you have encountered an error!",
                text: message,
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonColor: "#0E2D5B",
                denyButtonColor: "#990c53",
                denyButtonText: "Ignore",
                confirmButtonText: "Send Report",
              }).then((result) => {
                if (result.value) {
                  let user = JSON.parse(localStorage.getItem("userdetail"));
                  ReportServices.sendErrorReport({
                    error_page: window.location.href,
                    error_api: error.response.config.url,
                    error_message: message,
                    email: user.email,
                  });
                }
              });
            });
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Have another look :/",
          text: "It seems you left some fields empty",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
      }
    },
    statusColor(status) {
      let color = "text-t3";
      switch (status) {
        case "disputed":
          color = "text-orange-500";
          break;
        case "completed":
          color = "text-t3-secondary";
          break;
        case "booked":
          color = "text-t3-teritiary";
          break;
      }
      return color;
    },
    startAddELE() {
      this.addingELE = true;
    },
    cancelAddELE() {
      this.addingELE = true;
      if (!this.reportE.tasklevel_set) {
        this.reportELE.tasks.forEach((t) => {
          t.comprehension = null;
          t.fluency = null;
          t.structure = null;
          t.interaction = null;
          t.pronunciation = null;
          t.vocabulary = null;
        });
      } else {
        this.reportELE.tasks = this.reportE.tasklevel_set;
      }
    },
    addReportELEComment() {
      if (this.commentELE) {
        AssessmentService.addReportComment(this.reportE.id, {
          comment: this.commentELE,
        })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Report comment added successfully!",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 3000,
            });
            this.addingELEComment = true;
            this.getreportELE(this.id);
            this.$forceUpdate;
          })
          .catch((error) => {
            let message =
              Object.keys(error.response.data).at(0) +
              " : " +
              error.response.data[Object.keys(error.response.data).at(0)];
            Swal.fire({
              icon: "error",
              title: "It appears you have encountered an error!",
              text: message,
              showConfirmButton: true,
              showDenyButton: true,
              confirmButtonColor: "#0E2D5B",
              denyButtonColor: "#990c53",
              denyButtonText: "Ignore",
              confirmButtonText: "Send Report",
            }).then((result) => {
              if (result.value) {
                let user = JSON.parse(localStorage.getItem("userdetail"));
                ReportServices.sendErrorReport({
                  error_page: window.location.href,
                  error_api: error.response.config.url,
                  error_message: message,
                  email: user.email,
                });
              }
            });
          });
      }
    },
    addReportELE() {
      let checkFull = true;
      this.reportELE.tasks.forEach((t) => {
        if (
          t.comprehension === null ||
          t.fluency === null ||
          t.interaction === null ||
          t.structure === null ||
          t.pronunciation === null ||
          t.vocabulary === null
        ) {
          checkFull = false;
        }
      });

      if (checkFull) {
        if (!this.reportE.tasklevel_set) {
          AssessmentService.addReportAll(this.id, this.reportELE.tasks)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "This report has been added!",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
              });
              this.addingELE = true;
              this.getassessment(this.id);
              this.getreportELE(this.id);
              this.$forceUpdate;
            })
            .catch((error) => {
              let message =
                Object.keys(error.response.data).at(0) +
                " : " +
                error.response.data[Object.keys(error.response.data).at(0)];
              Swal.fire({
                icon: "error",
                title: "It appears you have encountered an error!",
                text: message,
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonColor: "#0E2D5B",
                denyButtonColor: "#990c53",
                denyButtonText: "Ignore",
                confirmButtonText: "Send Report",
              }).then((result) => {
                if (result.value) {
                  let user = JSON.parse(localStorage.getItem("userdetail"));
                  ReportServices.sendErrorReport({
                    error_page: window.location.href,
                    error_api: error.response.config.url,
                    error_message: message,
                    email: user.email,
                  });
                }
              });
            });
        } else {
          AssessmentService.updateReport(this.reportE.id, this.reportELE.tasks)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "This report has been updated!",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
              });
              this.addingELE = true;
              this.getassessment(this.id);
              this.getreportELE(this.id);
              this.$forceUpdate;
            })
            .catch((error) => {
              let message =
                Object.keys(error.response.data).at(0) +
                " : " +
                error.response.data[Object.keys(error.response.data).at(0)];
              Swal.fire({
                icon: "error",
                title: "It appears you have encountered an error!",
                text: message,
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonColor: "#0E2D5B",
                denyButtonColor: "#990c53",
                denyButtonText: "Ignore",
                confirmButtonText: "Send Report",
              }).then((result) => {
                if (result.value) {
                  let user = JSON.parse(localStorage.getItem("userdetail"));
                  ReportServices.sendErrorReport({
                    error_page: window.location.href,
                    error_api: error.response.config.url,
                    error_message: message,
                    email: user.email,
                  });
                }
              });
            });
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Have another look :/",
          text: "It seems you left some fields empty",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
      }
    },
    addAdminComment() {
      if (this.commentAdmin) {
        AssessmentService.addAdminComment(this.assessment.id, {
          comment: this.commentAdmin,
        })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Admin comment added successfully!",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 3000,
            });
            this.addingAdminComment = true;
            this.getassessment(this.id);
            this.$forceUpdate;
          })
          .catch((error) => {
            let message =
              Object.keys(error.response.data).at(0) +
              " : " +
              error.response.data[Object.keys(error.response.data).at(0)];
            Swal.fire({
              icon: "error",
              title: "It appears you have encountered an error!",
              text: message,
              showConfirmButton: true,
              showDenyButton: true,
              confirmButtonColor: "#0E2D5B",
              denyButtonColor: "#990c53",
              denyButtonText: "Ignore",
              confirmButtonText: "Send Report",
            }).then((result) => {
              if (result.value) {
                let user = JSON.parse(localStorage.getItem("userdetail"));
                ReportServices.sendErrorReport({
                  error_page: window.location.href,
                  error_api: error.response.config.url,
                  error_message: message,
                  email: user.email,
                });
              }
            });
          });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Invalid Request",
          text: "Please input the comment before saving changes.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
      }
    },
    updateAdminComment() {
      AssessmentService.putAdminComment(this.assessment.id, {
        comment: this.commentAdmin,
      })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Admin comment updated successfully!",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timerProgressBar: true,
            timer: 3000,
          });
          this.addingAdminComment = true;
          this.getassessment(this.id);
          this.$forceUpdate;
        })
        .catch((error) => {
          let message =
            Object.keys(error.response.data).at(0) +
            " : " +
            error.response.data[Object.keys(error.response.data).at(0)];
          Swal.fire({
            icon: "error",
            title: "It appears you have encountered an error!",
            text: message,
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonColor: "#0E2D5B",
            denyButtonColor: "#990c53",
            denyButtonText: "Ignore",
            confirmButtonText: "Send Report",
          }).then((result) => {
            if (result.value) {
              let user = JSON.parse(localStorage.getItem("userdetail"));
              ReportServices.sendErrorReport({
                error_page: window.location.href,
                error_api: error.response.config.url,
                error_message: message,
                email: user.email,
              });
            }
          });
        });
    },
    previewFiles(event) {
      var reader = new FileReader();
      reader.onload = function () {
        var output = document.getElementById("output-image");
        output.setAttribute("src", URL.createObjectURL(event.target.files[0]));
        document.getElementById("output-image").load();
      };
      reader.readAsDataURL(event.target.files[0]);
      this.currentFile = "event.target.value";
    },
    logStaff(id) {
      return this.staffOnly.find((s) => s.id === id);
    },

    assessmentCustomer() {
      return this.customer;
    },
    attachmentLoad() {
      let temp = Array.from(this.attachments);
      this.attachments = [];
      if (temp.length + this.assessmendRecordings.length > this.tasks.length) {
        Swal.fire({
          icon: "warning",
          title: "Hold",
          text:
            "You cannot upload more than " +
            this.tasks.length +
            " files. Try again.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
      } else {
        temp.forEach((t) => {
          this.attachments.push({ loader: "no", recording: t, task: null });
        });
      }
    },
    attachmentCancel() {
      this.attachments = [];
      this.addingRec = false;
    },
    submitAttachment(e) {
      e.preventDefault();
      let counter = 0;
      this.attachments.forEach((attachment) => {
        if (attachment.task !== "") {
          const getFormData = (object) =>
            Object.entries(object).reduce((fd, [key, val]) => {
              if (Array.isArray(val)) {
                val.forEach((v) => fd.append(key, v));
              } else {
                fd.append(key, val);
              }
              return fd;
            }, new FormData());
          attachment.loader = "load";
          RecordingService.sendRecording(this.id, getFormData(attachment))
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "The recording has been submitted!",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
              });
              counter++;
              attachment.loader = "yes";
              if (counter === this.attachments.length) {
                this.addingRec = false;
                this.attachments = [];
                this.getrecordings();
                this.$forceUpdate;
              }
            })
            .catch((error) => {
              let message =
                Object.keys(error.response.data).at(0) +
                " : " +
                error.response.data[Object.keys(error.response.data).at(0)];
              Swal.fire({
                icon: "error",
                title: "It appears you have encountered an error!",
                text: message,
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonColor: "#0E2D5B",
                denyButtonColor: "#990c53",
                denyButtonText: "Ignore",
                confirmButtonText: "Send Report",
              }).then((result) => {
                if (result.value) {
                  let user = JSON.parse(localStorage.getItem("userdetail"));
                  ReportServices.sendErrorReport({
                    error_page: window.location.href,
                    error_api: error.response.config.url,
                    error_message: message,
                    email: user.email,
                  });
                }
              });
              attachment.loader = "no";
            });
        } else {
          alert("Please select a task");
        }
      });
    },

    sort_by_id() {
      return function (elem1, elem2) {
        if (elem1.id < elem2.id) {
          return -1;
        } else if (elem1.id > elem2.id) {
          return 1;
        } else {
          return 0;
        }
      };
    },
    sortedTask() {
      return this.tasks.sort(this.sort_by_id());
    },
    filteredRecordings(taskid) {
      return this.recordings.filter(
        (cus) => cus.task === taskid && cus.assessment === parseInt(this.id)
      );
    },

    filteredTask(taskid, taskset) {
      let cal = taskset.find((cus) => cus.task === taskid);

      return cal;
    },
    questionTask(name) {
      return this.sampledQuestions.filter((q) => q.for_task === name);
    },

    questionFilter(questionList, type) {
      return questionList.filter((q) => q.type === type);
    },

    logMessage(method) {
      let message = "unknow method";
      switch (method) {
        case "CREATE":
          message = " has booked this assessment.";
          break;
        case "UPDATE":
          message = " has updated the status of this assessment.";
          break;
        case "UPDATE_DISPUTE":
          message = " has updateded the status of this assessment's dispute.";
          break;
        case "CREATE_REPORT":
          message = " has added a report.";
          break;
        case "UPDATE_REPORT":
          message = " has updated their report.";
          break;
        case "ADD_ATTACHMENT":
          message = " has added a recording.";
          break;
        case "THIRD_ASSESSOR_REPORT":
          message = " has added the third assessor's report.";
          break;
        case "SEAL_ASSESSMENT":
          message =
            "The assessment has been automatically sealed as per setting configuration.";
          break;
      }
      return message;
    },
    archiveRecording(id) {
      Swal.fire({
        icon: "warning",
        title: "Hold",
        text: "Are you sure you want to archive this recording?",
        showConfirmButton: true,
        showDenyButton: true,
        confirmButtonColor: "#0E2D5B",
        denyButtonColor: "#990c53",
      }).then((result) => {
        if (result.value) {
          RecordingService.archiveRecording(id)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "The recording has been archived!",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
              });

              this.getrecordings();
              this.$forceUpdate;
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "It appears you have encountered an error!",
                text: error.response.data[0],
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timerProgressBar: true,
                timer: 3000,
              });
            });
        }
      });
    },
    checkDisputeTime() {
      let todays = new Date().getTime() / (3600000 * 24);
      let checkd =
        new Date(this.assessment.completion_time).getTime() / (3600000 * 24) +
        this.settings.allow_customer_to_raise_dispute_within;
      if (
        (this.assessment.status === "completed" ||
          this.assessment.status === "sealed") &&
        todays <= checkd
      ) {
        return true;
      } else {
        return false;
      }
    },
    scopeCheck(scope) {
      let user = JSON.parse(localStorage.getItem("userdetail"));

      if (user.is_company) {
        return true;
      } else {
        return localStorage.getItem("scopes").includes(scope);
      }
    },
  },
  computed: {
    ...mapGetters("Users", [
      "customerOnly",
      "allStaff",
      "staffOnly",
      "allELE",
      "allOPE",
    ]),
    ...mapState({
      tasks: (state) => state.Assessments.tasks,
      customer: (state) => state.Users.customer,
      assessment: (state) => state.Assessments.assessment,
      logs: (state) => state.Assessments.logs,
      reportO: (state) => state.Assessments.opereport,
      reportE: (state) => state.Assessments.elereport,
      recordings: (state) => state.Recordings.recordings,
      questions: (state) => state.Questions.questions,
      sample: (state) => state.Samples.samples,
      settings: (state) => state.Settings.settings,
      disputes: (state) => state.Assessments.disputes,
    }),

    sampledQuestions() {
      return this.sample.questions;
    },
    isCompany() {
      let user = JSON.parse(localStorage.getItem("userdetail"));
      if (user.is_company) {
        return true;
      } else {
        return false;
      }
    },
    finalLevel() {
      if (this.assessment.final_level) {
        return this.assessment.final_level;
      } else {
        return 0;
      }
    },
    viewLogs() {
      return this.logs.filter((l) => l.method === "VIEW");
    },
    mainLogs() {
      return this.logs.filter((l) => l.method !== "VIEW");
    },
    currentOPE() {
      return this.allOPE.find((cus) => cus.id === this.assessment.ope);
    },
    currentELE() {
      return this.allELE.find((cus) => cus.id === this.assessment.ele);
    },
    assessmendRecordings() {
      return this.recordings.filter(
        (cus) => cus.assessment === parseInt(this.id)
      );
    },
  },

  created() {
    this.getassessment(this.id).then(() => {
      if (this.assessment.sample) {
        this.getsample(this.assessment.sample);
      }
      this.loadinger = false;
    });
    this.getAssessmentRecordings(this.id)
    this.getassessmentLogs(this.id);
    this.getsettings();
    this.getstaff();
    this.getTasks().then(() => {
      this.sortedTask().forEach((t) => {
        let insert = {
          id: null,
          task: t.id,
          pronunciation: null,
          structure: null,
          vocabulary: null,
          fluency: null,
          comprehension: null,
          interaction: null,
        };

        this.reportOPE.tasks.push(insert);
      });
      this.sortedTask().forEach((t) => {
        let insert = {
          task: t.id,
          pronunciation: null,
          structure: null,
          vocabulary: null,
          fluency: null,
          comprehension: null,
          interaction: null,
        };

        this.reportELE.tasks.push(insert);
      });
    });
    this.getrecordings();
    this.getreportELE(this.id);
    this.getreportOPE(this.id);
    this.getquestions();
    watchEffect(() => {
      if (this.reportO.comment) {
        this.commentOPE = this.reportO.comment;
      }

      if (this.reportE.comment) {
        this.commentELE = this.reportE.comment;
      }

      if (this.reportO.tasklevel_set) {
        this.reportOPE.tasks = this.reportO.tasklevel_set;
      }

      if (this.reportE.tasklevel_set) {
        this.reportELE.tasks = this.reportE.tasklevel_set;
      }
    });
  },
};
</script>
